import React from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

const Page404 = () => {
  const { pathname } = useLocation();

  if (pathname.includes('/calendar/')) {
    navigate(pathname);
    return null;
  }
  return <h1>Page not found</h1>;
};

export default Page404;
